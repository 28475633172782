import { AvatarBagde } from "@components/Avatar"
import { NavigationMenu } from "@components/NavigationMenu"

import { LogoHorizontal } from "@config/base/icons"

import { useCurrentUser } from "@stores/auth"

import { Container, Content } from "./styles"

export const Header: React.FC = () => {

  const { current } = useCurrentUser();

  return (
    <Container>
      <Content>
        <LogoHorizontal height={27} width={61} />
        <NavigationMenu />
        <AvatarBagde
          userName={current?.user.login}
        />
      </Content>
    </Container>
  )
}