import styled from 'styled-components';

export const StFormLogin = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  gap: 2.4rem;

  @media (min-width: 768px) {
    height: auto;
  }
`;


export const StTitleFormLogin = styled.h1`
  font-size: 1.8rem;
  line-height: 2.1rem;
  font-weight: 600;
`;

export const InputsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.8rem;

  & .input + .input {
    margin-top: 2.4rem;
  }
`;

export const LoginActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1.6rem;
  margin-top: 0.8rem;

  & a {
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 500;
    color: #0565D9;
    text-decoration: none;
    vertical-align: middle;
  }
`;