import styled, { css } from 'styled-components';

import { ButtonProps } from './types';

const buttonVariants = {
  primary: {
    background: '#0565D9',
    hover: '#0451AE',
    active: '#03336D',
    disabled: '#f2f2f2',
    color: '#fdfdfd',
    padding: '0',
    border: 'initial'
  },
  secondary: {
    background: '#fdfdfd',
    hover: '#f2f2f2',
    active: '#e5e5e5',
    disabled: '#f2f2f2',
    color: '#222529',
    padding: '0',
    border: '1px solid #B4B7B9'
  },
  tertiary: {
    background: 'transparent',
    hover: '#f2f2f2',
    active: '#e5e5e5',
    disabled: 'transparent',
    padding: '0',
    color: '#818385',
    border: 'initial'
  },
  icon: {
    background: '#FBFBFB',
    hover: '#f2f2f2',
    active: '#e5e5e5',
    disabled: 'transparent',
    color: '#818385',
    padding: '0.7rem',
    borderRadius: '0.6rem',
    border: '1px solid #B4B7B9',
  }
};

export const StButton = styled.button<ButtonProps>`
  all: unset;

  display: flex;
  justify-content: center;
  align-items: center;

  width: ${({ variant }) => (variant === 'icon' ? '4rem' : '100%')};
  height: ${({ variant }) => (variant === 'icon' ? 'auto' : '4rem')};
  aspect-ratio: ${({ variant }) => (variant === 'icon' ? '1' : 'auto')};
  
  border-radius: 0.4rem;
  border: ${({ variant }) =>
    buttonVariants[variant || 'primary'].border};

  background:
    ${({ variant }) => buttonVariants[variant || 'primary'].background};

  color: ${({ variant }) => buttonVariants[variant || 'primary'].color};

  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1rem;

  cursor: pointer;

  transition: color 0.2s ease, background 0.2s ease;

  padding:
    ${({ variant }) => buttonVariants[variant || 'primary'].padding || '0'};


  ${({ variant }) => variant !== 'icon' && css`

    & > span[class^="material"] {
      font-size: 2.4rem;
      color: ${buttonVariants[variant || 'primary'].color};
      margin-right: 0.8rem;
    }
  
  `}

  &:hover {
    background:
      ${({ variant }) => buttonVariants[variant || 'primary'].hover};
  }

  &:focus-visible:not(:disabled) {
    outline: 2px solid #ffffff;
    box-shadow: 0 0 1.2rem 0 #3A8AEA;
  }

  &:active:not(:disabled) {
    outline: none;
    background: ${({ variant }) => buttonVariants[variant || 'primary'].active};
    box-shadow: none;
  }

  &:disabled {
    background:
    ${({ variant }) => buttonVariants[variant || 'primary'].disabled};
    color: #818385;
    cursor: default;
  }

`;