import { Button } from "@components/Button"
import { Input } from "@components/Input"

import {
  PasswordStrength
} from "@src/modules/password/components/passwordStrength"
import { getPasswordStrength } from "@src/modules/password/utils/getPasswordStrength"

import { FormContainer } from "./styles"


export const ResetPasswordForm: React.FC = () => {

  const passwordStrength = getPasswordStrength("Asd1234!");

  return (
    <FormContainer>
      <Input
        type="password"
        label="Nova senha"
        placeholder="Digite aqui"
      />
      <Input
        type="password"
        label="Confirmar sua senha"
        placeholder="Digite aqui"
      />
      <PasswordStrength strength={passwordStrength} />
      <Button type="submit">Enviar</Button>
    </FormContainer>
  )
}