import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;

  padding: 0 1rem;
`;

export const Footer = styled.footer`
  display: flex;
  position: absolute;
  bottom: 4rem;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

export const FooterContent = styled.p`
  font-size: 1.4rem;
  color: #656A6E;
  text-align: center;
  margin: 0;
`;