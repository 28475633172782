

import { Icon } from "@components/Icon";

import { IconConsumption, IconHistoricalInfo, IconMeetings, IconRecords, IconStorage } from "@config/base/icons";

import { useDashboard } from "@services/dashboard";

import { formatStorage } from "@utils/format/storage";

import { BarChart } from "@dashboard/components/BarChart";

import { CardTitle } from "@src/modules/dashboard/components/Card";
import {
  CardBody,
  CardBodyDivider, CardBodyItemContainer, CardBodyItemLabel, CardBodyItemValue,
  CardContainer, CardContent, CardGridRow,
  CardHeader
} from "@src/modules/dashboard/components/Card/styles";
import { PieChart } from "@src/modules/dashboard/components/PieChart";

import { StCardGrid } from "./styles";


export const CardGrid: React.FC = () => {
  return (
    <StCardGrid>
      <CardGridRow>
        <MeetingsCard />
        <ConsumptionCard />
      </CardGridRow>
      <CardGridRow>
        <RecordsCard />
        <StorageCard />
      </CardGridRow>
      <CardGridRow>
        <HistoricalInformationCard />
      </CardGridRow>
    </StCardGrid>
  )
}

const MeetingsCard: React.FC = () => {

  const { data, isLoading } = useDashboard();

  if (data === undefined || isLoading) return (<CardContainer />);

  return (
    <CardContainer>
      <CardContent>
        <CardHeader>
          <CardTitle icon={<IconMeetings />}>Reuniões</CardTitle>
        </CardHeader>
        <CardBody>
          <CardBodyItemContainer>
            <CardBodyItemValue>{data.meetings.ongoing}</CardBodyItemValue>
            <CardBodyItemLabel>Em andamento</CardBodyItemLabel>
          </CardBodyItemContainer>
          <CardBodyItemContainer>
            <CardBodyItemValue>{data.meetings.scheduled}</CardBodyItemValue>
            <CardBodyItemLabel>Agendadas</CardBodyItemLabel>
          </CardBodyItemContainer>
          <CardBodyItemContainer>
            <CardBodyItemValue>{data.meetings.concluded}
            </CardBodyItemValue>
            <CardBodyItemLabel>Realizadas</CardBodyItemLabel>
          </CardBodyItemContainer>
          <CardBodyDivider />
          <CardBodyItemContainer>
            <CardBodyItemValue hasIcon>
              <Icon name="timer" iconStyle="round" type="icons" />
              {data.meetings.avgDuration}
            </CardBodyItemValue>
            <CardBodyItemLabel>Duração média</CardBodyItemLabel>
          </CardBodyItemContainer>
          <CardBodyItemContainer>
            <CardBodyItemValue hasIcon>
              <Icon name="person" iconStyle="round" type="icons" />
              0
            </CardBodyItemValue>
            <CardBodyItemLabel>Média de participantes</CardBodyItemLabel>
          </CardBodyItemContainer>
        </CardBody>
      </CardContent>
    </CardContainer>
  )
};

const ConsumptionCard: React.FC = () => {

  const { data, isLoading } = useDashboard();

  if (data === undefined || isLoading) return (<CardContainer />);

  return (
    <CardContainer>
      <CardContent>
        <CardHeader>
          <CardTitle icon={<IconConsumption />}>Consumo</CardTitle>
        </CardHeader>
        <CardBody>
          <CardBodyItemContainer>
            <CardBodyItemValue>{data.consumption.notRecorded}</CardBodyItemValue>
            <CardBodyItemLabel>Reuniões não gravadas</CardBodyItemLabel>
          </CardBodyItemContainer>
          <CardBodyItemContainer>
            <CardBodyItemValue>{data.consumption.recorded}</CardBodyItemValue>
            <CardBodyItemLabel>Reuniões gravadas</CardBodyItemLabel>
          </CardBodyItemContainer>
          <CardBodyItemContainer>
            <CardBodyItemValue>{data.consumption.total}</CardBodyItemValue>
            <CardBodyItemLabel>Total</CardBodyItemLabel>
          </CardBodyItemContainer>
        </CardBody>
      </CardContent>
    </CardContainer>
  )
}

const RecordsCard: React.FC = () => {

  const { data, isLoading } = useDashboard();

  if (data === undefined || isLoading) return (<CardContainer />);

  return (
    <CardContainer>
      <CardContent>
        <CardHeader>
          <CardTitle icon={<IconRecords />}>Gravações</CardTitle>
        </CardHeader>
        <CardBody style={{ gap: '3.2rem' }}>
          <CardBodyItemContainer>
            <CardBodyItemValue>
              {data.recording.recorded}
            </CardBodyItemValue>
            <CardBodyItemLabel>Reuniões gravadas</CardBodyItemLabel>
          </CardBodyItemContainer>
          <CardBodyItemContainer>
            <CardBodyItemValue>
              {data.recording.totalDuration}
            </CardBodyItemValue>
            <CardBodyItemLabel>Duração total</CardBodyItemLabel>
          </CardBodyItemContainer>
          <CardBodyItemContainer>
            <CardBodyItemValue>{formatStorage(data.storage.used)}</CardBodyItemValue>
            <CardBodyItemLabel>Utilizados</CardBodyItemLabel>
          </CardBodyItemContainer>
          <CardBodyDivider />
          <CardBodyItemContainer>
            <CardBodyItemValue hasIcon>
              <Icon name="timer" iconStyle="round" type="icons" />
              {data.recording.avgDuration}
            </CardBodyItemValue>
            <CardBodyItemLabel>Duração média</CardBodyItemLabel>
          </CardBodyItemContainer>
        </CardBody>
      </CardContent>
    </CardContainer>
  )
}

const StorageCard: React.FC = () => {

  const { data, isLoading } = useDashboard();

  if (data === undefined || isLoading) return (<CardContainer />);

  return (
    <CardContainer>
      <CardContent>
        <CardHeader>
          <CardTitle icon={<IconStorage />}>Armazenamento</CardTitle>
        </CardHeader>
        <CardBody>
          <CardBodyItemContainer>
            <CardBodyItemValue>{formatStorage(data.storage.used)}</CardBodyItemValue>
            <CardBodyItemLabel>Utilizados</CardBodyItemLabel>
          </CardBodyItemContainer>
          <CardBodyItemContainer>
            <CardBodyItemValue>{formatStorage(data.storage.available)}</CardBodyItemValue>
            <CardBodyItemLabel>Disponíveis</CardBodyItemLabel>
          </CardBodyItemContainer>
          <CardBodyItemContainer>
            <CardBodyItemValue>{formatStorage(data.storage.dailyAvg)}</CardBodyItemValue>
            <CardBodyItemLabel>Média de consumo diário</CardBodyItemLabel>
          </CardBodyItemContainer>
        </CardBody>
      </CardContent>
      <PieChart />
    </CardContainer>
  )
}

const HistoricalInformationCard: React.FC = () => {
  return (
    <CardContainer style={{ width: '100%' }}>
      <CardContent style={{ width: '100%' }}>
        <CardHeader>
          <CardTitle icon={<IconHistoricalInfo />}>
            Informações históricas
          </CardTitle>
        </CardHeader>
        <CardBody style={{ width: '100%' }}>
          <BarChart />
        </CardBody>
      </CardContent>
    </CardContainer>
  )
}

