

import { MainLayout } from "@layouts/main";


import { DashboardFilter } from "@dashboard/components/Filter";

import { CardGrid } from "@src/modules/dashboard/components/CardGrid";


export const Dashboard: React.FC = () => { 
  return (
    <MainLayout>
      <DashboardFilter />
      <CardGrid />
    </MainLayout>
  )
}