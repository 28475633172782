/**
 * 
 * @param duration Duration in hours
 * @returns A string with the duration formatted as 'Xh Ymin'
 */
export const formatDuration = (duration: number): string => {

  const hours = Math.floor(duration);
  const minutes = Math.round((duration - hours) * 60);


  if (hours === 0) {
    return `${minutes}min`;
  }

  if (minutes === 0) {
    return `${hours}h`;
  }

  if (minutes === 60) {
    return `${hours + 1}h`;
  }

  return `${hours}h ${minutes}min`;
}