export { ReactComponent as LogoHorizontal } from './LogoV4H_Horizontal.svg';
export { ReactComponent as IconConsumption } from './iconeConsumption.svg';
export {
  ReactComponent as IconHistoricalInfo
} from './iconeHistoricalInfo.svg';
export { ReactComponent as IconMeetings } from './iconeMeetings.svg';
export { ReactComponent as IconRecords } from './iconeRecords.svg';
export { ReactComponent as IconStorage } from './iconeStorage.svg';
export { ReactComponent as LogoToBackgroundDark } from './iconeV4HToDark.svg';
export { ReactComponent as LogoToBackgroundLight } from './iconeV4HToLight.svg';


