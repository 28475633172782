import { ROUTES } from '@config/routes/names';

import { KeyProps } from './types';

export const names: KeyProps = {
  '/': 'Início',
  '/billing': 'Faturamento',
  '/dashboard': 'Dashboard',
};

export const links: KeyProps = {
  '/': ROUTES.PRIVATE.ROOT(),
  '/billing': ROUTES.PRIVATE.BILLING(),
  '/dashboard': ROUTES.PRIVATE.DASHBOARD(),
};
