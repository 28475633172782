/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectHTMLAttributes, useState } from 'react';

import { Icon } from '@components/Icon';

import { Container, Message, SelectContainer, SelectLabel } from './styles';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name?: string;
  label?: string;
  className?: string;
  message?: string;
  width?: string;
  height?: string;
  error?: boolean;
  border?: boolean;
  minimal?: boolean;
  labelStyle?: React.CSSProperties;
  register?: () => any;
  horizontal?: boolean;
}

export const Select: React.FC<SelectProps> = ({
  children,
  name,
  label,
  className,
  message,
  error = false,
  border = true,
  width = '100%',
  height = '40px',
  register,
  required = false,
  labelStyle = {},
  minimal,
  horizontal,
  ...rest
}) => {
  const [focus, setFocus] = useState<boolean>(false);

  const mTop = label ? '4px' : '0px';
  const mBottom = message ? '2px' : '0px';
  const { disabled } = rest;

  if (register) register();

  if (required) label = `${label}*`;

  return (
    <Container width={width} className={className} horizontal={horizontal}>
      {label && <SelectLabel style={labelStyle}>{label}</SelectLabel>}
      <SelectContainer
        style={{ marginTop: mTop, marginBottom: mBottom }}
        height={height}
        focus={focus}
        disabled={disabled}
        error={error}
        border={border}
        minimal={minimal}
      >
        <select
          {...rest}
          disabled={disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          name={name}
        >
          {children}
        </select>
        <Icon
          name="keyboard_arrow_down"
          iconStyle="outlined"
          type="icons"
          className='icon'
        />
      </SelectContainer>
      <Message error={error}>{message}</Message>
    </Container>
  );
};

