import { MutationDashboardVariables } from "@services/dashboard/types";

export function getInterval(period: MutationDashboardVariables['period']): { from?: string, until?: string } {
  const today = new Date();

  const until = today.toISOString()
  switch (period) {
    case 'all':
      return { until }
    case 'lastMonth': {
      const lastMonth = new Date(today);
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      return {
        from: lastMonth.toISOString(),
        until
      }
    }
    case 'lastYear': {
      const lastYear = new Date(today);
      lastYear.setFullYear(lastYear.getFullYear() - 1);
      return {
        from: lastYear.toISOString(),
        until
      }
    }
    case 'today': {
      const startOfDay = new Date(today);
      startOfDay.setHours(0, 0, 0, 0);
      return {
        from: startOfDay.toISOString(),
        until: new Date().toISOString()
      }
    }
    default:
      return { until }
  }
}