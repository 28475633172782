import { Navigate, Outlet } from 'react-router-dom';

import { useCurrentUser } from '@stores/auth';

export interface PrivateRouteType {
  children?: React.ReactElement;
  redirectPath?: string;
}

export const PrivateRoute: React.FC<PrivateRouteType> = ({
  children,
  redirectPath = '/',
}) => {
  const { isAuthenticated } = useCurrentUser();

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} />;
  }

  return children || <Outlet />;
};
