import { Button } from "@components/Button"
import { Input } from "@components/Input"

import { FormContainer } from "./styles"

export const RequestLinkForm: React.FC = () => {
  return (
    <FormContainer>
      <Input type="email" label="E-mail" placeholder="E-mail" />
      <Button type="submit">Enviar</Button>
    </FormContainer>
  )
}