import { formatDuration } from "@utils/format/duration";
import { formatNumber } from "@utils/format/number";

import {
  DashboardHistory, DashboardHistoryMap, DashboardMap, DashboardResponse
} from "./types";

export type {
  DashboardHistory,
  DashboardHistoryMap,
  DashboardMap,
  DashboardResponse
};

export const dashboardHistoryMap =
  (data: DashboardHistory, scale = 'month'): DashboardHistoryMap => {

    const { totalConferenceQuantity, notRecordedConferenceQuantity, recordedConferenceQuantity, conferenceHours, timeReference } = data;

    const timeFormatted = timeReference.map((time) => {
      const date = new Date(time);
      const formatted = Intl.DateTimeFormat('pt-BR', {
        year: '2-digit',
        month: 'short'
      }).format(date).replace('.', '').replace(' de ', '/');
      return formatted;
    })

    const timeFormattedDay = timeReference.map((time) => {
      const date = new Date(time);
      const formatted = Intl.DateTimeFormat('pt-BR', {
        day: '2-digit',
        month: 'short'
      }).format(date).replace('.', '').replace(' de ', '/');
      return formatted;
    })

    const timeFormattedHour = timeReference.map((time) => {
      const date = new Date(time);
      const formatted = Intl.DateTimeFormat('pt-BR', {
        hour: '2-digit',
        minute: '2-digit'
      }).format(date);
      return formatted;
    })

    const getFormattedTime = () => {
      switch (scale) {
        case 'day':
          return timeFormattedDay;
        case 'hour':
          return timeFormattedHour;
        default:
          return timeFormatted;
      }
    }

    return getFormattedTime().map((time, index) => ({
      time,
      totalConferenceQuantity: totalConferenceQuantity[index],
      notRecordedConferenceQuantity: notRecordedConferenceQuantity[index],
      recordedConferenceQuantity: recordedConferenceQuantity[index],
      conferenceHours: conferenceHours[index],
    }
    ));
  }

export const dashboardMap =
  (data: DashboardResponse): DashboardMap => {

    const { session, billing, storage: storageResponse, room } = data;

    const meetings: DashboardMap['meetings'] = {
      ongoing: formatNumber(room.ongoing),
      scheduled: formatNumber(room.scheduled),
      concluded: formatNumber(room.concluded.total),
      avgDuration: formatDuration(room.concluded.avgHours),
    }

    const consumption: DashboardMap['consumption'] = {
      notRecorded: formatNumber(billing.conferences, {
        currency: 'BRL',
      }),
      recorded: formatNumber(billing.recordings, {
        currency: 'BRL',
      }),
      total: formatNumber(billing.total, {
        currency: 'BRL',
      }),
    }

    const recording: DashboardMap['recording'] = {
      recorded: formatNumber(session.recorded.total),
      avgDuration: formatDuration(session.concluded.avgHours),
      totalDuration: formatDuration(session.concluded.hours),
      used: storageResponse.used,
    }

    const storage: DashboardMap['storage'] = {
      available: storageResponse.max,
      used: storageResponse.used,
      dailyAvg: storageResponse.dailyAvg,
    }

    return {
      meetings,
      consumption,
      recording,
      storage,
    };

  }