import usePageCurrent from "@hooks/usePageCurrent";

import { SubHeaderContainer, SubHeaderContent, SubHeaderTitle } from "./styles";

export const SubHeader: React.FC = () => { 

  const { title } = usePageCurrent();

  return (
    <SubHeaderContainer>
      <SubHeaderContent>
        <div />
        <SubHeaderTitle>
          {title}
        </SubHeaderTitle>
        <div />
      </SubHeaderContent>
    </SubHeaderContainer>
  )
}