import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserName = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.4rem;
  margin-right: 1rem;
`;

export const Avatar = styled.img`
  width: 4rem;
  aspect-ratio: 1;
  border-radius: 50%;
`;

export const AvatarInitials = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.6rem;
  border-radius: 50%;
  background-color: #f0f0f0;
`;
