/* eslint-disable import/order */
import { MainLayout } from "@layouts/main";

import { BillingCard } from "@billing/components/Card";

export const Billing: React.FC = () => { 
  return (
    <MainLayout>
      <BillingCard />
    </MainLayout>
  )
};