import type { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';


import { baseApi } from '@libs/axios';
import { queryClient } from '@libs/react-query';

import { getInterval } from '@utils/interval';
import {
  DashboardHistory, DashboardHistoryMap, DashboardMap, DashboardResponse, dashboardHistoryMap, dashboardMap
} from '@utils/map/dashboard';
import parseData from '@utils/parseData';

import type { ApiError, ApiResponse } from '@entities/Response';

import { listDashboard, listHistorical } from './keys';
import { Filters, MutationDashboardResponse, MutationDashboardVariables } from './types';

const getDashboardData = async (filters?: Filters): Promise<DashboardMap> => {
  return await
    baseApi.post<ApiResponse<DashboardResponse>>(`/dashboard`, filters)
      .then(parseData).then(dashboardMap);
}

const getHistoricalData = async (filters?: Filters): Promise<DashboardHistoryMap> => {
  return await baseApi
    .post<ApiResponse<DashboardHistory>>(`/dashboard/history`, {
      scale: 'month',
      ...filters
    })
    .then(parseData).then(data => dashboardHistoryMap(data, filters?.scale));
}

export const useHistoricalInformation = (options?: UseQueryOptions<DashboardHistoryMap, ApiError>) => {
  return useQuery(
    {
      queryKey: listHistorical(),
      refetchInterval: false,
      retry: 3,
      staleTime: Infinity,
      queryFn: () => getHistoricalData(),
      ...options,
    }
  );
}

export const useDashboard = (options?: UseQueryOptions<DashboardMap, ApiError>) => {
  return useQuery(
    {
      queryKey: listDashboard(),
      refetchInterval: false,
      retry: 3,
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: true,
      queryFn: () => getDashboardData(),
      ...options,
    }
  );
}

export const useMutateDashboard = (options?: UseMutationOptions<MutationDashboardResponse, ApiError, MutationDashboardVariables>) => {
  return useMutation(
    {
      mutationFn: async ({ org, unit, period }) => {

        const orgValue = org === 'all' ? undefined : org;
        const unitValue = unit === 'all' ? undefined : unit;
        // const periodObject: Record<
        //   MutationVariables['period'], { until?: string; from?: string; }
        // > = {
        //   all: {},
        //   lastMonth: { until: '2021-08-01', from: '2021-07-01' },
        //   lastYear: { until: '2021-08-01', from: '2020-08-01' },
        //   today: { until: '2021-08-01', from: '2021-07-01' },
        // };

        const filters = {
          org: orgValue,
          unit: orgValue ? unitValue : undefined,
          ...getInterval(period),
        };

        const scale:
          Record<
            MutationDashboardVariables['period'], 'month' | 'day' | 'hour'
          > = {
          all: 'month',
          lastMonth: 'day',
          lastYear: 'month',
          today: 'hour',
        };

        const dashboard = await getDashboardData(filters);
        const historical = await getHistoricalData({
          ...filters,
          scale: scale[period],
        });

        return { dashboard, historical };
      },
      onSuccess: async (data) => {
        queryClient.setQueryData(listDashboard(), data.dashboard);
        queryClient.setQueryData(listHistorical(), data.historical);
      },
      ...options,
    }
  )
}