import styled, { css } from 'styled-components';


const commonStyle = css`
  padding: 1.6rem 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  border: 1px solid #DEDEDE;
  border-radius: 0.4rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FiltersContainer = styled.div`
  ${commonStyle}
  max-width: 47.7rem;
  border-right: 1px solid #DEDEDE;
  flex-direction: column;
  gap: 0.8rem;
`;

export const Content = styled.div`
  ${commonStyle}
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InfoTitle = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  color: #444A51;
  margin-bottom: 0.8rem;
`;

export const InfoValue = styled.span`
  font-weight: 600;
  font-size: 3.2rem;
  color: #005BC8;
  margin-bottom: 1.6rem;
`;

export const InfoPeriod = styled.span`

  font-size: 1.4rem;
  color: #444A51;
  margin-bottom: 0.8rem;
`;

export const InfoValidity = styled(InfoPeriod)`
  margin-bottom: 0;
`;

export const ActionsContainer = styled.div`

  width: 100%;
  max-width: 22.4rem;
  & > button:last-child {
    margin-top: 1.6rem;
  }

`;
