import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { NavigationMenuLinkStyledProps } from './types';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerMenuItem = styled.div`
  display: flex;
  positon: relative;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  cursor: pointer;
`;

const MenuItemLinkDecoration = css`

  color: #054586;
  font-weight: 700;

  &::after {
    content: "";
    position: absolute;
    width: 3.2rem;
    bottom: -2px;
    left: 50%;
    height: 1px;
    background-color: #054586;
    transition: 0.5s;
    transform: translateX(-50%);
  }
`;

export const NavigationMenuLink = styled(Link) <NavigationMenuLinkStyledProps>`
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 500;
  color: #222529;
  position: relative;

  ${({ isActive }) => isActive && MenuItemLinkDecoration}
`;