/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react';

const useFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const requestFullscreen = useCallback(() => {
    try {
      const anyDocument = document as any;

      if (document.body.requestFullscreen && !document.fullscreenElement) {
        document.body.requestFullscreen();
      } else if (
        anyDocument.body.mozRequestFullScreen &&
        !anyDocument.mozFullScreenElement
      ) {
        anyDocument.body.mozRequestFullScreen();
      } else if (
        anyDocument.body.webkitRequestFullscreen &&
        !anyDocument.webkitFullscreenElement
      ) {
        anyDocument.body.webkitRequestFullscreen();
      } else if (
        anyDocument.body.msRequestFullscreen &&
        !anyDocument.msFullscreenElement
      ) {
        anyDocument.body.msRequestFullscreen();
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const exitFullscreen = useCallback(() => {
    const anyDocument = document as any;

    try {
      if (document.exitFullscreen && document.fullscreenElement) {
        document.exitFullscreen();
      } else if (
        anyDocument.mozCancelFullScreen &&
        anyDocument.mozFullScreenElement
      ) {
        anyDocument.mozCancelFullScreen();
      } else if (
        anyDocument.webkitExitFullscreen &&
        anyDocument.webkitFullscreenElement
      ) {
        anyDocument.webkitExitFullscreen();
      } else if (
        anyDocument.msExitFullscreen &&
        anyDocument.msFullscreenElement
      ) {
        anyDocument.msExitFullscreen();
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  document.addEventListener('fullscreenchange', () => {
    setIsFullscreen(!!document.fullscreenElement);
  });

  const handleFullscreen = useCallback(() => {
    if (isFullscreen) {
      exitFullscreen();
    } else {
      requestFullscreen();
    }

    setIsFullscreen(prevState => !prevState);
  }, [exitFullscreen, isFullscreen, requestFullscreen]);

  return { isFullscreen, handleFullscreen };
};

export default useFullscreen;