import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
  background-color: #fdfdfd;
  border-bottom: 1px solid #f2f4f7;
`;

export const Content = styled.main`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;