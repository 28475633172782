import { forwardRef } from "react";

import {
  InputContainer, StInput, StInputLabel, StInputMessageError
} from "./styles";
import { InputProps } from "./types";

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, ...rest }, ref) => {
    return (
      <InputContainer className="input">
        <StInputLabel>{label}</StInputLabel>         
        <StInput type="text" placeholder="" ref={ref} {...rest} />
        {!!rest.error && <StInputMessageError>{rest.error}</StInputMessageError>}
      </InputContainer>
    )
});

Input.displayName = "Input";

export { Input };
