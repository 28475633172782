
import { LogoHorizontal } from "@config/base/icons";

import { PasswordRecoveryLayout } from "@layouts/passwordRecovery";


import {
  ResetPasswordForm
} from "@src/modules/password/components/form/resetPassword";

import { Container, Details, HeaderContainer, Title } from "./styles";


export const ResetPassword: React.FC = () => { 
  return (
    <PasswordRecoveryLayout>
      <Container>
        <LogoHorizontal width={130} height={56} />
        <HeaderContainer>
          <Title>Redefinir senha</Title>
          <Details>
            Digite e confirme sua senha nos campos abaixo
          </Details>
        </HeaderContainer>
        <ResetPasswordForm />
      </Container>
    </PasswordRecoveryLayout>
  );
}