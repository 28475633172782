
import { LogoHorizontal } from "@config/base/icons";

import { PasswordRecoveryLayout } from "@layouts/passwordRecovery";

import {
  RequestLinkForm
} from "@src/modules/password/components/form/requestLink";

import { Container, Details, HeaderContainer, Title } from "./styles";

export const RequestLink: React.FC = () => {
  return (
    <PasswordRecoveryLayout>
      <Container>
        <LogoHorizontal width={130} height={56} />
        <HeaderContainer>
          <Title>Recuperar senha</Title>
          <Details>
            Insira seu e-mail no campo abaixo e enviaremos um link para redefinir sua senha.
          </Details>
        </HeaderContainer>
        <RequestLinkForm />
      </Container>
    </PasswordRecoveryLayout>
  );
}