/* eslint-disable @next/next/no-img-element */
import { Avatar, AvatarInitials, Container, UserName } from "./styles";
import { AvatarBagdeProps } from "./types";

export const AvatarBagde: React.FC<AvatarBagdeProps> =
  ({ url, userName, ...props }) => {

    const hasUrl = url && url.length > 0;
    const initials = userName?.split(' ').map(name => name[0]).join('');

  return (
    <Container {...props}>
      <UserName>{userName}</UserName>
      {hasUrl ?
        <Avatar src={url} alt={userName} /> :
        <AvatarInitials>{initials}</AvatarInitials>
      }
    </Container>
  );
}