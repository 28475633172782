import { BaseLayout } from "@layouts/types";

import { Container, Footer, FooterContent } from "./styles";

export const PasswordRecoveryLayout: React.FC<BaseLayout> = ({ children }) => {
  return (
    <Container>
      {children}
      <Footer>
        <FooterContent>
          ©️ Copyright | Política de privacidade e Termos de uso.
        </FooterContent>
      </Footer>
    </Container>
  )
};