import styled from 'styled-components';

import bannerWiseApi from '@config/base/images/banner_wiseapi.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100dvh;
  background-color: #242b86;
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: row-reverse;
  }
`;

export const BannerContainer = styled.aside`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #fdfdfd;

  background-image: url(${bannerWiseApi});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (min-width: 768px) {
    flex: 2;
    padding: 16.1rem 0;
    height: 100dvh;
  }

  @media (min-width: 1366px) {
    flex: 3;
  }
`;

export const ChildrenContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 4.8rem 2rem;
  padding-bottom: 2.4rem;

  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  background-color: #fdfdfd;
  color: #222529;

  @media (min-width: 768px) {
    flex: 1;
    border-radius: 0;
    height: 100dvh;
  }

  @media (min-width: 1366px) {
    padding: 7.2rem;
  }
`;

export const Header = styled.header`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
  }
`;

export const Footer = styled.footer`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 1366px) {
    align-items: flex-start;
  }
`;

export const SmallText = styled.a`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: center;
  text-decoration: none;

  color: #62666c;

  @media (min-width: 1366px) {
    text-align: left;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  gap: 3.6rem;

  p {
    font-size: 2.2rem;
    line-height: 2.5rem;
    font-weight: 500;
    margin-top: 2rem;
  }

  h1 {
    font-size: 4.6rem;
    line-height: 5.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  @media (min-width: 768px) {
    height: 100%;
  }
`;

export const Divider = styled.div`
  display: none;

  width: 6rem;
  height: 0.5rem;
  background-color: #fdfdfd;
  margin: 2.4rem 0;

  border-radius: 0.6rem;

  box-shadow: 0px 0px 2rem rgba(0, 0, 0, 0.25);

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const InfoBanner = styled.div`
  display: none;

  & p {
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 400;
    text-align: center;
    max-width: 38.7rem;
  }

  @media (min-width: 768px) {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;

export const UsefulLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 400;
`;

export const StLink = styled.a`
  color: #fdfdfd;
  text-decoration: underline;
  cursor: pointer;

  font-weight: 700;
`;
