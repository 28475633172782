import { Button } from "@components/Button"
import { Icon } from "@components/Icon"
import { Select } from "@components/Select"

import { ActionsContainer, Container, Content, FiltersContainer, InfoContainer, InfoPeriod, InfoTitle, InfoValidity, InfoValue } from "./styles"

export const BillingCard: React.FC = () => {
  return (
    <Container>
      <FiltersContainer>
        <Select name="unit" label="Mês:" horizontal>
          <option value="all">Todos os setores</option>
          <option key={1} value={1}>
            <span>Selecione</span>
          </option>
        </Select>
        <Select name="unit" label="Setor:" horizontal>
          <option value="all">Todos os setores</option>
          <option key={1} value={1}>
            <span>Selecione</span>
          </option>
        </Select>
        <Select name="unit" label="Visualização:" horizontal>
          <option value="all">Todos os setores</option>
          <option key={1} value={1}>
            <span>Selecione</span>
          </option>
        </Select>
      </FiltersContainer>
      <Content>
        <InfoContainer>
          <InfoTitle>
            Valor total
          </InfoTitle>
          <InfoValue>
            R$ 268,27
          </InfoValue>
          <InfoPeriod>Vencimento: <b>31/08/2022</b></InfoPeriod>
          <InfoValidity>Vigência: <b>01/08/2022 a 31/08/2022</b></InfoValidity>
        </InfoContainer>
        <ActionsContainer>
          <Button>
            <Icon name="download" iconStyle="rounded" />
            Baixar fatura
          </Button>
          <Button variant="secondary">
            <Icon name="print" iconStyle="rounded" />
            Cancelar
          </Button>
        </ActionsContainer>
      </Content>
    </Container>
  )
}