import styled from 'styled-components';

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: #222529;
  cursor: pointer;
`;

export const StCheckbox = styled.input`
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid #222529;
  border-radius: 0.3rem;
  appearance: none;
  cursor: pointer;
  border: 1px solid #B4B7B9;
  position: relative;
  outline: none;

  &:checked {
    background: #0565D9;
    border: 1px solid #0565D9;
  }

  &:focus-visible:not(:checked) {
    border: 2px solid #0565D9;
    box-shadow: 0 0 1.2rem 0 #3A8AEA;
  }

  &:focus-visible {
    border: 2px solid #ffffff;
    box-shadow: 0 0 1.2rem 0 #3A8AEA;
  }

  &:checked:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%) rotate(-45deg);
    width: 0.9rem;
    height: 0.6rem;
    border: 0.2rem solid #ffffff;
    border-top: none;
    border-right: none;
  }
`;

export const CheckboxText = styled.p`
  user-select: none;
`;