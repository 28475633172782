import { StrictMode } from 'react';

import { createRoot } from 'react-dom/client';

import Router from '@config/routes';
import HistoryProvider from '@config/routes/_CustomRouter';

import 'material-icons';
import 'material-symbols';


const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <HistoryProvider>
      <Router />
    </HistoryProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
