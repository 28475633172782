import styled from "styled-components";

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;

  flex-wrap: wrap;

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }

  & > h3 {
    text-wrap: nowrap;
  }
`;

export const SelectContainer = styled.div<{ width?: string }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  @media (min-width: 1024px) {
    width: ${({ width }) => width ?? '100%'};
  }
`;

export const SelectLabel = styled.label`
  font-size: 1.4rem;
  font-weight: 500;
  color: #222529;
`;