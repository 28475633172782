import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { navigate } from '@config/routes/_CustomRouter';

import type { UserAuthResponse } from '@services/auth/types';

import type { AuthState } from './types';

export const useCurrentUser = create<AuthState>()(
  devtools(
    persist(
      (set, get) => {
        const setCurrentUser = (data?: UserAuthResponse) => {
          set({ current: data, isAuthenticated: Boolean(data) });
          const { isAuthenticated } = get();
          if (isAuthenticated) navigate('/dashboard');
        };

        return {
          setCurrentUser,
          isAuthenticated: false,
          current: undefined,
        };
      },
      {
        name: '@console/persist'
      }
    )
  )
);