import { useMemo } from "react";

import { Container, ContainerMenuItem, NavigationMenuLink } from "./styles";
import { NavigationMenuItemProps } from "./types";

export const NavigationMenu: React.FC = () => { 
  return (
    <Container>
      <NavigationMenuItem title="Dashboard" url="/dashboard" />
      <NavigationMenuItem title="Faturamento" url="/billing" />
    </Container>
  )
}

const NavigationMenuItem: React.FC<NavigationMenuItemProps> =
  ({ title, url }) => { 

  const isActive = useMemo(() => {
    return window.location.pathname === url
  }, [url])
  
  return (
    <ContainerMenuItem>
      <NavigationMenuLink isActive={isActive} to={url}>
        {title}
      </NavigationMenuLink>
    </ContainerMenuItem>
  )
};