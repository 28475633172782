
import { Header } from "@components/Header";
import { SubHeader } from "@components/SubHeader";

import { BaseLayout } from "@layouts/types";

import {
  Body,
  Container
} from "./styles";

export const MainLayout: React.FC<BaseLayout> = ({ children }) => {
  return (
    <Container>
      <div style={{ width: '100%' }}>
        <Header />
        <SubHeader />
      </div>
      <Body>
        {children}
      </Body>
    </Container>
  );
};