import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';


import { links, names } from './constants';

const usePageCurrent = () => {
  const router = useLocation();

  const title = useMemo(() => {
    return names[router.pathname];
  }, [router.pathname]);

  const breadcrumb = useMemo(() => {
    const routes = router.pathname
      .split('/')
      .filter((item) => item !== '')
      .map((path) => {
        const name = `/${path}`;
        return {
          label: names[name],
          link: links[name],
        };
      });
    return routes;
  }, [router.pathname]);

  return {
    title,
    breadcrumb,
  };
};

export default usePageCurrent;
