
import { ReactNode } from "react"

import {
  CardBodyItemContainer, CardBodyItemLabel, CardBodyItemValue, StCardTitle
} from "./styles"

export const CardTitle: React.FC<{ icon?: ReactNode, children: ReactNode }> = ({ icon, children }) => { 

  const titleIcon = icon ? <span>{icon}</span> : null

  return (
    <StCardTitle>{titleIcon}{children}</StCardTitle>
  )
}

export const CardBodyItem: React.FC<{ icon?: ReactNode, value: number, label: string }> = ({ value, label, icon }) => {

  const valueIcon = icon ? <span className="value-icon">{icon}</span> : null;

  return (
    <CardBodyItemContainer>
      <CardBodyItemValue hasIcon={!!icon}>{valueIcon}{value}</CardBodyItemValue>
      <CardBodyItemLabel>{label}</CardBodyItemLabel>
    </CardBodyItemContainer>
  )
}