import { useMemo } from "react";

import { Cell, Label, Pie, PieChart as RCPieChart } from "recharts";

import { useDashboard } from "@services/dashboard";


export const PieChart: React.FC = () => {

  const { data, isLoading } = useDashboard();

  const dataPie = useMemo(() => {
    return [
      { name: 'Used', value: data?.storage.used, fill: '#7E79E8' },
      { name: 'Unused', value: data?.storage.available, fill: '#E3E3E3' },
    ]
  }, [data?.storage.available, data?.storage.used])

  if (data === undefined || isLoading) return null;
  
  const total = data.storage.available + data.storage.used;
  const percentageUsed = Math.round((data.storage.used / total) * 100);

  return (
    <RCPieChart width={114} height={114}>
      <Pie
        data={dataPie}
        innerRadius={45}
        outerRadius={57}
        activeIndex={1}
        startAngle={90}
        endAngle={90 - 360}
        dataKey="value"
      >
        {dataPie.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.fill} />
        ))}
        <Label
          value={`${percentageUsed}% usado`}
          position="center"
        />
      </Pie>
    </RCPieChart>
  )

}