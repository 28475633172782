import { IconProps } from "./types"

export const Icon: React.FC<IconProps> =
  ({ name, type = 'symbols', iconStyle = 'outlined', ...rest }) => {
  return (
    <span
      {...rest}
      className={`material-${type}-${iconStyle} ${rest.className}`}
    >
      {name}
    </span>
  )
}