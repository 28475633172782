import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3.2rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 2.8rem;
  text-align: center;
  color: #222529;
`;

export const Details = styled.p`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.8rem;
  color: #656A6E;
  text-align: center;
  max-width: 33.1rem;
  margin-top: 0.8rem;
`;