/* eslint-disable @typescript-eslint/no-explicit-any */
import { Controller, FormProvider, useForm } from "react-hook-form";
import { toast } from "sonner";

import { Button } from "@components/Button";
import { Checkbox } from "@components/Checkbox";
import { Input } from "@components/Input";

import { useAuth } from "@services/auth";
import { MutationAuthVariables } from "@services/auth/types";

import { ErrorMessages } from "@utils/errors";
import { requiredRule } from "@utils/form/rules";

import { ApiError } from "@entities/Response";

import {
  InputsContainer, LoginActionsContainer, StFormLogin, StTitleFormLogin
} from "./styles";

export const FormLogin: React.FC = () => {

  const form = useForm<MutationAuthVariables>();

  const { handleSubmit, control, formState: { errors } } = form;
  const { mutate, isPending } = useAuth({
    onError: (error: ApiError) => {
      toast.error(
        ErrorMessages[
          error.response?.data.errors[0].code ?? 'USER_COULD_NOT_FOUND'
        ]
      );
    }
  });

  const handleLogin = (data: MutationAuthVariables) => {
    mutate(data);
  }

  return (
    <FormProvider {...form}>
      <StFormLogin onSubmit={
        handleSubmit(handleLogin, data => console.log('data invalid', data))
      }>
        <StTitleFormLogin>Fazer login</StTitleFormLogin>
        <InputsContainer>
          <Controller
            control={control}
            name="login"
            rules={{ ...requiredRule() }}
            render={({ field }) => (
              <Input
                label="Email"
                placeholder="usuario@email.com"
                error={errors.login?.message}          
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            rules={{
              ...requiredRule(),
            }}
            render={({ field }) => (
              <Input
                label="Senha"
                placeholder="Digite sua senha"
                type="password"
                error={errors.password?.message}
                {...field}
              />

            )}
          />
          <LoginActionsContainer>
            <Checkbox label="Lembrar-me" />
            <a href="#">Esqueceu sua senha?</a>
          </LoginActionsContainer>
        </InputsContainer>
        <Button type="submit" disabled={isPending}>
          {isPending ? 'Carregando...' : 'Entrar'}
        </Button>
      </StFormLogin>
    </FormProvider>
  );
}