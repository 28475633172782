import type { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import { baseApi } from '@libs/axios';
import { queryClient } from '@libs/react-query';

import parseData from '@utils/parseData';

import type { ApiError, ApiResponse } from '@entities/Response';

import { listOrg, listOrgUnits } from './keys';
import { OrgsResponse } from './types';

export const useOrgList = (options?: UseQueryOptions<OrgsResponse, ApiError>) => {
  return useQuery(
    {
      queryKey: listOrg(),
      refetchInterval: false,
      retry: 3,
      staleTime: Infinity,
      queryFn:
        () => baseApi.post<ApiResponse<OrgsResponse>>(`/orgs/list`)
          .then(parseData),
      ...options,
    }
  );
}

export const useOrgUnitList = (
  options?: UseMutationOptions<OrgsResponse, ApiError, string>) => {
  return useMutation(
    {
      mutationKey: listOrgUnits(),
      mutationFn: async (orgShort: string) => {
        console.log('orgId', orgShort)
        return await baseApi.post<ApiResponse<OrgsResponse>>(`/orgs/${orgShort}/units/list`)
          .then(parseData);
      },
      ...options,
    }, queryClient
  );
}