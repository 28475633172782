import { useEffect, useState } from 'react';


import { useMutateDashboard } from '@services/dashboard';
import { MutationDashboardVariables } from '@services/dashboard/types';

const useDashboardFilter = () => {

  const [filter, setFilter] = useState<MutationDashboardVariables>({
    org: 'all',
    unit: 'all',
    period: 'all',
  });

  const { mutate } = useMutateDashboard();

  const handleFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    mutate(filter);
  }, [filter, mutate]);

  return {
    filter,
    handleFilter,
  }

}

export default useDashboardFilter;