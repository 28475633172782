import styled from 'styled-components';

export const SubHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;
  background-color: #fdfdfd;
  border-bottom: 1px solid #DEDEDE;
`;

export const SubHeaderContent = styled.main`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 122.2rem;
`;

export const SubHeaderTitle = styled.h1`
  font-size: 1.4rem;
  font-weight: 500;
  color: #656A6E;
`;
