import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4rem;
  padding: 2.4rem;

  background-color: #ffffff;
  border: 1px solid #dedede;
  border-radius: 0.8rem;

  width: 100%;

  @media (min-width: 768px) {
    width: 44rem;
  }
`;