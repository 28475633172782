import { passwordStrength } from "check-password-strength";

export function getPasswordStrength(password: string): number {
  const strength = passwordStrength(password);

  const { id } = strength;

  const strengthMap: Record<number, number> = {
    0: 25,
    1: 50,
    2: 75,
    3: 100,
  }

  return strengthMap[id];
}