import { CheckboxContainer, CheckboxText, StCheckbox } from "./styles"
import { CheckboxProps } from "./types"

export const Checkbox: React.FC<CheckboxProps> = ({ label, ...rest }) => {

  return (
    <CheckboxContainer htmlFor="dont-forgot-me">
      <StCheckbox id="dont-forgot-me" type="checkbox" {...rest} />
      <CheckboxText>{label}</CheckboxText>
    </CheckboxContainer>
  )
}