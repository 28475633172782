import styled, { css } from 'styled-components';

export const CardContainer = styled.div`
  /* flex: 1; */
  /* min-width: fit-content; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px solid #DEDEDE;
  background-color: #ffffff;
  border-radius: 0.8rem;
  padding: 2.4rem;
  padding-bottom: 1.6rem;
  gap: 3.2rem;
  box-shadow: 0px 4px 2rem rgba(0, 0, 0, 0.05);

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardBody = styled.div.attrs({ className: 'card-body' })`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.4rem;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

export const StCardTitle = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 500;
  color: #436385;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
  }
`;

export const CardBodyItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
`;

export const CardBodyItemLabel = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
  color: #656A6E;
  width: auto;
`;

export const CardBodyItemValue = styled.span<{ hasIcon?: boolean }>`
  font-size: 2.6rem;
  font-weight: 700;
  color: #233242;
  display: flex;
  align-items: center;
  justify-content: center;

  text-wrap: nowrap;

  ${({ hasIcon }) => hasIcon && css`
    & > span {
      margin-right: 0.8rem;
    }
  `}
`;

export const CardBodyDivider = styled.div`
  width: 2px;
  height: 6rem;
  background-color: #DEDEDE;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

export const CardGridRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  gap: 1.6rem;
  width: 100%;

  @media (min-width: 1200px) {
    flex-direction: row;

    ${CardContainer} {
      width: 100%;
    }
  }
`;