import styled from 'styled-components';

import { InputProps } from './types';

export const InputContainer = styled.div`
  width: 100%;
  gap: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StInputLabel = styled.label`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: #222529;
`;

const borderColorByStatus = (error: string | undefined):
  Record<'border' | 'focus' | 'shadow', string> => {
  return {
    border: error ? '#DD0404' : '#b4b7b9',
    focus: error ? '#DD0404' : '#3A8AEA',
    shadow: error ? 'transparent' : '#3A8AEA',
  };
}

export const StInput = styled.input<InputProps>`
  width: 100%;
  height: 4rem;

  padding: 1.2rem 1.6rem;
  
  border: 1px solid ${({ error }) => borderColorByStatus(error).border};
  border-radius: 0.6rem;

  background-color: #fdfdfd;

  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;

  transition: border-color 0.2s ease;

  &:placeholder {
    color: #656a6e;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;
  }

  &:disabled  {

    background-color: #f2f2f2;

    &:placeholder {
      color: #818385;
    }
  }

  &:focus:not(:disabled)  {
    outline: none;
    border-width: 2px;
    padding: 1.1rem 1.5rem;
    border-color: ${({ error }) => borderColorByStatus(error).focus};
    box-shadow: 0 0 1.2rem 0
      ${({ error }) => borderColorByStatus(error).shadow};
  }

  &:active:not(:disabled) {
    outline: none;
    border-color: ${({ error }) => borderColorByStatus(error).focus};
    box-shadow: 0 0 0 0.2rem
      ${({ error }) => borderColorByStatus(error).shadow};
  }
`;

export const StInputMessageError = styled.span`
  color: #DD0404;
  font-weight: 500;
  font-size: 1.4rem;
`;