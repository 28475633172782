import { UseMutationOptions, useMutation } from "@tanstack/react-query"

import { baseApi } from "@libs/axios"

import { useCurrentUser } from "@stores/auth"

import { ApiError } from "@entities/Response"

import { MutationAuthResponse, MutationAuthVariables } from "./types"

export const useAuth = (
  options?:
    Partial<
      UseMutationOptions<MutationAuthResponse, ApiError, MutationAuthVariables>
    >
) => {
  return useMutation({
    mutationFn: async ({ login, password }) => {
      const { data } =
        await baseApi.post<MutationAuthResponse>('/auth/user', {
          login, password
        });

      return data;
    },
    onSuccess(data) {
      const { setCurrentUser } = useCurrentUser.getState();
      setCurrentUser(data);
    },
    ...options
  })
}