import { Navigate } from "react-router-dom";

import { AuthLayout } from "@layouts/auth";

import { useCurrentUser } from "@stores/auth";

import { FormLogin } from "@login/components/form";

const LoginScreen = () => {

  const { isAuthenticated } = useCurrentUser();

  if (isAuthenticated) return <Navigate to="/dashboard" />;

  return (
    <AuthLayout>
      <FormLogin />
    </AuthLayout>
  );
};

export default LoginScreen;
