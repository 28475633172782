import { Outlet } from 'react-router-dom';
import { Toaster } from 'sonner';


import { GlobalStyle } from '@config/base/styles/global';

import ReactQueryProvider from '@libs/react-query';

const App = (): JSX.Element => {
  return (
    <ReactQueryProvider>
      <div className="App">
        <Outlet />
        <GlobalStyle />
        <Toaster
          position="top-right"
          duration={5000}
          richColors
        />
      </div>
    </ReactQueryProvider>
  );
};

export default App;
